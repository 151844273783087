import React, { useState } from "react";
import queryString from "query-string";

import {
  BannerPage,
  ContainerContent,
  ContainerContentFixed,
  ContainerCta,
  InternalLinkTypes,
  Layout,
  SEO,
  InspectionForm,
} from "../components";
import { Row, Col } from "antd";

const Inspection = ({ location }) => {
  const [display] = useState(() => {
    if (location && location.search) {
      const params = queryString.parse(location.search);
      if (params.display) {
        return params.display;
      }
      return null;
    }
  });

  return (
    <Layout>
      <SEO title="Inspection" description={"Inspect our displays"} />
      <BannerPage
        title={"Book Inspection"}
        subTitle={
          "Walk-ins are welcome at all our display homes but booking an inspection is recommended to ensure discussion time with the sales consultant"
        }
      />

      {/*<ContentLanguage />*/}
      <ContainerContent>
        <ContainerContentFixed>
          <Row>
            <Col md={{ span: 12, offset: 6 }} sm={24}>
              <InspectionForm selectedDisplay={display} />
            </Col>
          </Row>
        </ContainerContentFixed>
      </ContainerContent>
      <ContainerCta
        title={"Need more information?"}
        secondaryLink={InternalLinkTypes.Contact}
        secondaryText={"Contact Our Team"}
      />
    </Layout>
  );
};

export default Inspection;
